<template>
  <di>
    <v-snackbar
      v-model="isRoundedSnackbarVisible"
      top
      :light="$vuetify.theme.dark"
    >
      Você copiou: <strong>{{ copiedTextSnackbar }}</strong>
    </v-snackbar>
    <div class="container-action">
      <v-btn
        color="purple"
        data-test="adm-system-button"
        class="button-access"
        @click="updateClient"
      >
        <span v-if="!loadingUpdate">cliente atualizado</span>
        <v-progress-circular
          v-else
          color="white"
          indeterminate
        >
        </v-progress-circular>
      </v-btn>
    </div>
    <v-expansion-panels multiple>
      <v-expansion-panel>
        <v-expansion-panel-header>
          Dados Básicos
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div class="content-wrapper">
            <v-text-field
              label="CPF/CNPJ"
              :value="infosClient.basicData.document"
              outlined
              readonly
              hide-details
              dense
              @click="clickCopy"
            >
            </v-text-field>
            <v-text-field
              label="Nome"
              :value="infosClient.basicData.name"
              outlined
              readonly
              hide-details
              dense
              @click="clickCopy"
            >
            </v-text-field>
            <v-text-field
              label="Tipo de contribuinte"
              :value="infosClient.basicData.typeContr"
              outlined
              readonly
              hide-details
              dense
              @click="clickCopy"
            >
            </v-text-field>
            <v-text-field
              label="Inscrição estadual"
              :value="infosClient.basicData.stateRegistration"
              outlined
              readonly
              hide-details
              dense
              @click="clickCopy"
            >
            </v-text-field>
            <v-text-field
              label="UF IE"
              :value="infosClient.basicData.uf"
              outlined
              readonly
              hide-details
              dense
              @click="clickCopy"
            >
            </v-text-field>
          </div>
          <div class="content-wrapper">
            <v-text-field
              label="RG"
              :value="infosClient.basicData.RG"
              outlined
              readonly
              hide-details
              dense
              @click="clickCopy"
            >
            </v-text-field>
            <v-text-field
              label="Rg Órgão emissor"
              :value="infosClient.basicData.RGOrgEmiss"
              outlined
              readonly
              hide-details
              dense
              @click="clickCopy"
            >
            </v-text-field>
            <v-text-field
              label="Número do produtor rural"
              :value="infosClient.basicData.numProdRural"
              outlined
              readonly
              hide-details
              dense
              @click="clickCopy"
            >
            </v-text-field>
          </div>
          <div class="content-wrapper">
            <v-text-field
              label="Nascimento/Abertura"
              :value="infosClient.basicData.birthDate"
              outlined
              readonly
              hide-details
              dense
              @click="clickCopy"
            >
            </v-text-field>
            <v-text-field
              label="Inscrição municipal"
              :value="infosClient.basicData.inscrMun"
              outlined
              readonly
              hide-details
              dense
              @click="clickCopy"
            >
            </v-text-field>
          </div>
          <div class="content-wrapper">
            <v-text-field
              label="Sexo"
              :value="infosClient.basicData.sex"
              outlined
              readonly
              hide-details
              dense
              @click="clickCopy"
            >
            </v-text-field>
            <v-text-field
              label="Estado civil"
              :value="infosClient.basicData.estCiv"
              outlined
              readonly
              hide-details
              dense
              @click="clickCopy"
            >
            </v-text-field>
            <v-text-field
              label="Escolaridade"
              :value="infosClient.basicData.escol"
              outlined
              readonly
              hide-details
              dense
              @click="clickCopy"
            >
            </v-text-field>
          </div>
          <div class="content-wrapper">
            <v-text-field
              label="Nacionalidade"
              :value="infosClient.basicData.nasciona"
              outlined
              readonly
              hide-details
              dense
              @click="clickCopy"
            >
            </v-text-field>
            <v-text-field
              label="ID estrangeiro"
              :value="infosClient.basicData.idEstran"
              outlined
              readonly
              hide-details
              dense
              @click="clickCopy"
            >
            </v-text-field>
            <v-text-field
              label="Naturalidade"
              :value="infosClient.basicData.natural"
              outlined
              readonly
              hide-details
              dense
              @click="clickCopy"
            >
            </v-text-field>
          </div>
          <div class="content-wrapper">
            <div class="col-min">
              <v-text-field
                label="Deficiência"
                :value="infosClient.basicData.defic"
                outlined
                readonly
                hide-details
                dense
                @click="clickCopy"
              >
              </v-text-field>
            </div>
            <div class="col-min">
              <v-text-field
                label="Quantidade de dependentes"
                :value="infosClient.basicData.qtdDep"
                outlined
                readonly
                hide-details
                dense
                @click="clickCopy"
              >
              </v-text-field>
            </div>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>
          Endereços
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div class="content-wrapper">
            <v-text-field
              label="CEP"
              :value="infosClient.adresses.cep"
              outlined
              readonly
              hide-details
              dense
              @click="clickCopy"
            >
            </v-text-field>
            <v-text-field
              label="Logradouro"
              :value="infosClient.adresses.logrado"
              outlined
              readonly
              hide-details
              dense
              @click="clickCopy"
            >
            </v-text-field>
            <v-text-field
              label="Número"
              :value="infosClient.adresses.num"
              outlined
              readonly
              hide-details
              dense
              @click="clickCopy"
            >
            </v-text-field>
          </div>
          <div class="content-wrapper">
            <v-text-field
              label="País"
              :value="infosClient.adresses.country"
              outlined
              readonly
              hide-details
              dense
              @click="clickCopy"
            >
            </v-text-field>
            <v-text-field
              label="UF"
              :value="infosClient.basicData.uf"
              outlined
              readonly
              hide-details
              dense
              @click="clickCopy"
            >
            </v-text-field>
            <v-text-field
              label="Município"
              :value="infosClient.adresses.munic"
              outlined
              readonly
              hide-details
              dense
              @click="clickCopy"
            >
            </v-text-field>
          </div>
          <div class="content-wrapper">
            <v-text-field
              label="Bairro"
              :value="infosClient.adresses.bairr"
              outlined
              readonly
              hide-details
              dense
              @click="clickCopy"
            >
            </v-text-field>
            <v-text-field
              label="Complemento"
              :value="infosClient.adresses.complem"
              outlined
              readonly
              hide-details
              dense
              @click="clickCopy"
            >
            </v-text-field>
            <v-text-field
              label="Ponto de referência"
              :value="infosClient.adresses.pointRef"
              outlined
              readonly
              hide-details
              dense
              @click="clickCopy"
            >
            </v-text-field>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>
          Contatos
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div class="content-wrapper">
            <v-text-field
              label="Número 01"
              :value="infosClient.contacts.num01"
              outlined
              readonly
              hide-details
              dense
              @click="clickCopy"
            >
            </v-text-field>
            <v-text-field
              label="Número 02"
              :value="infosClient.contacts.num02"
              outlined
              readonly
              hide-details
              dense
              @click="clickCopy"
            >
            </v-text-field>
            <v-text-field
              label="Número 03"
              :value="infosClient.contacts.num03"
              outlined
              readonly
              hide-details
              dense
              @click="clickCopy"
            >
            </v-text-field>
          </div>
          <div class="col-min">
            <v-text-field
              label="E-mail"
              :value="infosClient.contacts.email"
              outlined
              readonly
              hide-details
              dense
              @click="clickCopy"
            >
            </v-text-field>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>
          Parentesco
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div class="content-wrapper">
            <v-text-field
              label="Nome Pai"
              :value="infosClient.family.father"
              outlined
              readonly
              hide-details
              dense
              @click="clickCopy"
            >
            </v-text-field>
            <v-text-field
              label="Nome Mãe"
              :value="infosClient.family.mother"
              outlined
              readonly
              hide-details
              dense
              @click="clickCopy"
            >
            </v-text-field>
          </div>
          <div class="content-wrapper">
            <v-text-field
              label="Data Nascimento Pai"
              :value="infosClient.family.birthFather"
              outlined
              readonly
              hide-details
              dense
              @click="clickCopy"
            >
            </v-text-field>
            <v-text-field
              label="Data Nascimento Mãe"
              :value="infosClient.family.birthMother"
              outlined
              readonly
              hide-details
              dense
              @click="clickCopy"
            >
            </v-text-field>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </di>
</template>
<script>
import axiosIns from '@/plugins/axios'
import localStorageSlim from 'localstorage-slim'

export default {
  data() {
    return {
      infosClient: {
        basicData: {
          document: '',
          name: '',
          typeContr: '',
          stateRegistration: '',
          uf: '',
          RG: '',
          UFRG: '',
          RGEmiss: '',
          RGOrgEmiss: '',
          numProdRural: '',
          birthDate: '',
          dataEman: '',
          cnh: '',
          category: '',
          cnhEmis: '',
          inscrMun: '',
          sex: '',
          estCiv: '',
          escol: '',
          group: '',
          nasciona: '',
          idEstran: '',
          natural: '',
          defic: '',
          qtdDep: '',
        },
        adresses: {
          cep: '',
          logrado: '',
          num: '',
          country: '',
          uf: '',
          munic: '',
          bairr: '',
          complem: '',
          pointRef: '',
        },
        contacts: {
          num01: '',
          num02: '',
          num03: '',
          email: '',
        },
        family: {
          mother: '',
          father: '',
          birthMother: '',
          birthFather: '',
        },
      },
      isRoundedSnackbarVisible: false,
      copiedTextSnackbar: '',
      loadingUpdate: false,
    }
  },

  created() {
    const dataLocal = localStorageSlim.get('dataClientNVTI', { decrypt: true })
    const cpfOrCnpj = localStorageSlim.get('documentNVTI', { decrypt: true })

    const type = this.isCPFOrCNPJ(cpfOrCnpj)
    const qtd = dataLocal?.PESSOASLIGADAS?.filter(pess => !!pess.CPF)

    if (type === 'CPF') {
      this.infosClient.basicData.document = dataLocal.CADASTRAIS.CPF
      this.infosClient.basicData.name = dataLocal.CADASTRAIS.NOME
      this.infosClient.basicData.RG = dataLocal.CADASTRAIS.RG
      this.infosClient.basicData.birthDate = dataLocal.CADASTRAIS.NASC
      this.infosClient.basicData.RGOrgEmiss = dataLocal.CADASTRAIS.ORGAOEMISSOR
      this.infosClient.basicData.sex = dataLocal.CADASTRAIS.SEXO
      this.infosClient.basicData.estCiv = dataLocal.CADASTRAIS.ESTADOCIVIL
      this.infosClient.basicData.escol = dataLocal.CADASTRAIS.POSSIVELESCOLARIDADE
      this.infosClient.basicData.nasciona = dataLocal.CADASTRAIS.NACIONALIDADE
      this.infosClient.basicData.qtdDep = qtd.length
      this.infosClient.family.mother = dataLocal.CADASTRAIS.NOME_MAE
      this.infosClient.family.birthMother = dataLocal.CADASTRAIS.NASC_MAE
    }

    if (type === 'CNPJ') {
      this.infosClient.basicData.document = dataLocal.CADASTRAIS.CNPJ
      this.infosClient.basicData.name = dataLocal.CADASTRAIS.RAZAO
      this.infosClient.basicData.stateRegistration = dataLocal.CADASTRAIS.INSCRICAO_ESTADUAL
      this.infosClient.basicData.birthDate = dataLocal.CADASTRAIS.DATA_ABERTURA
    }

    this.infosClient.basicData.uf = dataLocal.ENDERECOS[0].UF
    this.infosClient.adresses.cep = dataLocal.ENDERECOS[0].CEP
    this.infosClient.adresses.logrado = dataLocal.ENDERECOS[0].LOGRADOURO
    this.infosClient.adresses.num = dataLocal.ENDERECOS[0].NUMERO
    this.infosClient.adresses.munic = dataLocal.ENDERECOS[0].CIDADE
    this.infosClient.adresses.bairr = dataLocal.ENDERECOS[0].BAIRRO
    this.infosClient.adresses.complem = dataLocal.ENDERECOS[0].COMPLEMENTO
    this.infosClient.contacts.num01 = dataLocal.TELEFONES[0].TELEFONE
    this.infosClient.contacts.num02 = dataLocal.TELEFONES[1]?.TELEFONE
    this.infosClient.contacts.num03 = dataLocal.TELEFONES[2]?.TELEFONE
    this.infosClient.contacts.email = dataLocal.EMAILS[0]?.EMAIL
  },

  methods: {
    async clickCopy(value) {
      await navigator.clipboard.writeText(value.target.value)

      this.isRoundedSnackbarVisible = true

      this.copiedTextSnackbar = value.target.value
    },

    isCPFOrCNPJ(value) {
      return value.length === 11 ? 'CPF' : 'CNPJ'
    },

    async updateClient() {
      const { id } = localStorageSlim.get('userProfile', { decrypt: true })
      this.loadingUpdate = true

      const body = {
        name_corporate_reason: this.infosClient.basicData.name,
        cpf_cnpj: this.infosClient.basicData.document,
        state_registration: '',
        uf_ie: this.infosClient.basicData.uf,
        rg: this.infosClient.basicData.RG,
        uf_rg: this.infosClient.basicData.uf,
        rg_date_of_issue: '',
        rg_issuing_authority: '',
        municipal_registration: '',
        date_of_birth_opened_date: this.infosClient.basicData.birthDate.split('/').reverse().join('-'),
        cnh_number: '',
        cnh_category: '',
        cnh_date_of_issue: '',
        sex: this.infosClient.basicData.sex,
        marital_status: this.infosClient.basicData.estCiv,
        education_level: this.infosClient.basicData.escol,
        nationality: this.infosClient.basicData.nasciona,
        foreigner_id: '',
        naturalness: this.infosClient.basicData.nasciona,
        zip_code_one: this.infosClient.adresses.cep,
        avenue_one: this.infosClient.adresses.logrado,
        address_number_one: this.infosClient.adresses.num,
        address_complement_one: this.infosClient.adresses.complem,
        country_one: 'Brasil',
        address_uf_one: this.infosClient.basicData.uf,
        municipality_one: this.infosClient.adresses.munic,
        district_one: this.infosClient.adresses.bairr,
        address_reference_one: '',
        zip_code_two: '',
        avenue_two: '',
        address_number_two: '',
        address_complement_two: '',
        country_two: '',
        address_uf_two: '',
        municipality_two: '',
        district_two: '',
        address_reference_two: '',
        cellphone_number: this.infosClient.contacts.num01,
        commercial_phone_number: this.infosClient.contacts.num02,
        email: this.infosClient.contacts.email,
        user_id: id,
      }

      try {
        await axiosIns.post('/api/v1/registration_update/client/store', body)
      } catch (error) {
        console.log(error)
      } finally {
        this.loadingUpdate = false
      }
    },
  },
}
</script>

<style scoped>
.content-wrapper{
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}
.col-min{
  width: 20%;
}

.container-action{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0px 0px 10px 0px;
}

</style>
